import React from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Shop from './Shop';
import Catalog from './Catalog';
import Promotion from './Promotions';
import Reservations from './Reservations';
import ReservationCancellation from './Reservations/cancellation';
import Home from './Home';
import MenuPics from './MenuPics';
import Recipe from './MenuPics/recipe';
import NotFoundPage from './404';

function AppRoutes() {
  return(
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/de" element={<Home />} />
        <Route exact path="/shop" element={<Shop />} />
        <Route exact path="/catalog" element={<Catalog />} />
        <Route exact path="/reservations" element={<Reservations />} />
        <Route exact path="/reservations/:access_code" element={<Reservations />} />
        <Route exact path="/reservations/cancel/:access_code" element={<ReservationCancellation />} />
        <Route exact path="/promotions" element={<Promotion />} />
        <Route exact path="/menu-pics" element={<MenuPics />} />
        <Route exact path="/recipes/:id" element={<Recipe />} />
        {/* <Route path="*"> <NotFoundPage /> </Route> */}
      </Routes>
    </Router>
  )
}

export default AppRoutes;
