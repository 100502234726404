import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { getProducts } from "./api";
import "./styles.css";
import { setItem, getItem } from '../store';
import Footer from "../Footer";
import Header from "../Header";
import Texts from "../common/texts";

const ProductDisplay = ({ product, addToCart, lang, mode }) => (
    <div className="product">
      <div className="product-name-container">
        <div className="product-name">{product.name[lang]}</div>
        <div className="product-description">{product.description[lang]}</div>
      </div>
      <div className="product-price">{(product.prices[mode] || {}).amount} EUR</div>
      <div className="product-action">
        <button onClick={() => addToCart(product)}><Texts root="catalog" identifier="add_to_cart" lang={lang} /></button>
      </div>
    </div>
);

const ProductSection = ({ products, sectionName, addToCart, lang, mode }) => {
  const allProducts = (products && products[sectionName] && products[sectionName].products) ? products[sectionName].products : [];
  const activeProducts = allProducts.filter(p => !!p.active);
  if (activeProducts && !!activeProducts.length) {
    return (
      <>
    <div className="product">
      <h2 className="section-title">{products[sectionName].name[lang]}</h2>
    </div>
    { activeProducts.map((product, index) => product.active && (
      <ProductDisplay key={`${sectionName}_${index}`} product={product} addToCart={addToCart} lang={lang} mode={mode} />
    ))}
    </>
    )
  } else {
    return null;
  }
}

export default function Basket() {
  const [mode, setMode] = useState('dine-in');
  const [products, setProducts] = useState({});
  const [basketItems, setBasketItems] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [basketPreferences, setBasketPreferences] = useState({});
  const [basket, setBasket] = useState({});
  const [lang, setLang] = useState('en');
  const [email, setEmail] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  let navigate = useNavigate();

  const homePage = () => navigate(`/`);

  const calculateBasket = (product) => {
    const price = (product.prices || {})[mode];
    const basketItem = {
      name: product.name[lang],
      sys_name: product.name['en'],
      price: price.amount,
      priceId: price.id,
      prefs: Object.values(basketPreferences).join('; ')
    };

    setTotalAmount(totalAmount + price.amount);
    const item = basket[basketItem.priceId] || { ...basketItem, qty: 0, total: 0 };
    item.qty += 1;
    item.total += basketItem.price;
    setBasket({...basket, [basketItem.priceId]: item });

    setBasketItems([ ...basketItems, basketItem ]);
  }

  const addToCart = (product) => {
    
    if (product.options) {
      setSelectedProduct(product);
      setShowPopup(true);
    } else {
      calculateBasket(product);
    }
  };

  const closePopup = () => {
    setBasketPreferences({});
    setSelectedProduct({});
    setShowPopup(false);
  }

  const saveBasketPreferences = () => {
    calculateBasket(selectedProduct);
    closePopup();
  }

  const formSubmit = (event) => {
    setItem('basket', JSON.stringify(basketItems));
    setItem('totalAmount', totalAmount);
    return true;
  }

  const isPurchaseAllowed = () => {
    return (mode === 'dine-in' && totalAmount >= 15) || totalAmount >=30;
  }

  const removeBasketItem = (key) => {
    const item = basket[key];
    if (item) {
      const basketItem = basketItems.filter(b => b.priceId === key)[0];
      const index = basketItems.indexOf(basketItem);
      basketItems.splice(index, 1);
      setBasketItems(basketItems);

      item.qty -= 1;
      item.total -= basketItem.price;
      let updatedBasket = { ...basket };
      setTotalAmount(totalAmount - basketItem.price);

      if (item.qty > 0) {
        updatedBasket[key] = item;
      } else {
        delete updatedBasket[key];
      }

      setBasket(updatedBasket);
    }
  }

  useEffect(() => {
    setLang(getItem("shop-lang") || 'en');
    setMode(getItem('order_mode') === 'pickup' ? 'dine-in': 'delivery');
    setEmail(getItem("customer_email") || '');

    if (!getItem('delivery_date') || !getItem('order_mode')) {
      return homePage();
    }

    getProducts().then(products => {
      setProducts(products || {});
    });
  }, [sessionStorage]);

  return (
    <>
    <div>
      <Header updateLang={(attr) => setLang(attr) } />

      { !!basketItems.length && 
        <div className="price-header">
          <a className="simpleText" href="#totalAmount">
            <div className="row">
              <div><strong><Texts root="catalog" identifier="total" lang={lang} /></strong></div>
              <div><strong>{totalAmount} EUR</strong></div>
            </div>
          </a>
        </div>
      }
    </div>

    <div className="catalog container mt-5">
      <div className="product-menu">
        <div className="product">
        <div className="product-name"><h1><Texts root="catalog" identifier="menu" lang={lang} /></h1></div>
        <div className="product-price"></div>
        <div className="product-action">
        </div>
      </div>

        <ProductSection sectionName="holiday_menu" products={products} addToCart={addToCart} lang={lang} mode={mode} />
        <ProductSection sectionName="kathi_rolls" products={products} addToCart={addToCart} lang={lang} mode={mode} />
        <ProductSection sectionName="chaat" products={products} addToCart={addToCart} lang={lang} mode={mode} />
        <ProductSection sectionName="spicy_treats" products={products} addToCart={addToCart} lang={lang} mode={mode} />
        <ProductSection sectionName="heavy_duty" products={products} addToCart={addToCart} lang={lang} mode={mode} />
        <ProductSection sectionName="extras" products={products} addToCart={addToCart} lang={lang} mode={mode} />
        <ProductSection sectionName="desserts" products={products} addToCart={addToCart} lang={lang} mode={mode} />
        <ProductSection sectionName="drinks" products={products} addToCart={addToCart} lang={lang} mode={mode} />
        <ProductSection sectionName="wines" products={products} addToCart={addToCart} lang={lang} mode={mode} />
      </div>

      { !!basketItems.length && 

      <div className="basket">
        <h3><Texts root="catalog" identifier="your_order" lang={lang} /></h3>
        { Object.keys(basket).map( (key, index) => (
          <div className="basket-line-item" key={`item_${index}`}>
            <div className="row">
              <div className="item-name">{basket[key].qty} x {basket[key].name}</div>
              <div>{basket[key].total} EUR</div>
              <div onClick={() => removeBasketItem(key)} className="delete-icon"><i className="fas fa-minus-circle"></i></div>
            </div>
          </div>
        ))}

        <div className="basket-line-item" id="totalAmount">
            <div className="row">
              <div className="item-name"><strong><Texts root="catalog" identifier="total" lang={lang} /></strong></div>
              <div><strong>{totalAmount} EUR</strong></div>
              <div>&nbsp;</div>
            </div>
          </div>
        <div className="top_margin">
          <form action="/api/create-checkout-session" method="POST" onSubmit={(event) => formSubmit() }>
            <input type="hidden" key="email" name="email" value={email} />
          { basketItems.map( (product, index) => (
            <input type="hidden" key={`item_${index}`} name={`item_${index}`} value={product.priceId} />
          ))}

          { isPurchaseAllowed() && 
            <input type="submit" value={Texts({ root: 'catalog', identifier: 'confirm', lang })} />
          }

          { !isPurchaseAllowed() && 
          <div className="min-order-msg">
             <Texts root="catalog" identifier="note" lang={lang} />
             <Texts root="catalog" identifier={mode === 'dine-in' ? 'min_pickup_order': 'min_delivery_order'} lang={lang} />
          </div>
          }
          </form>
        </div>
      </div>
      }
    </div>

    <div className={showPopup ? 'product-options popup-active' : 'product-options' }>
      <div className="overlay"></div>
      <div className="popup-body">
        {!!selectedProduct && !!selectedProduct.name && (
          <div className="selected-product-name">
            <h3>{selectedProduct.name[lang]}</h3>
            <span className="close-icon" onClick={() => closePopup()}><i className="far fa-times-circle"></i></span>
          </div>
        )}
        <div className="popup-content">
          <ul>
            {!!selectedProduct && !!selectedProduct.options && selectedProduct.options.map((o, ok) => (
              <li key={o.id + ok}><strong>{o.title[lang]}</strong>
                <ul>
                  {o.items.map((i, ik) => (
                    <div key={i + ik}>
                      <label className="form-control">
                        <input type="radio" name={o.id} value={i['en']} onChange={() => setBasketPreferences({ ...basketPreferences, [o.id]: i['en'] }) }/>
                        <span>{i[lang]}</span>
                      </label>
                    </div>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <div>
            <button onClick={() => saveBasketPreferences()}><Texts root="catalog" identifier="save" lang={lang} /></button>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={lang} />
    </>
  )
}