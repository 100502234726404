import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { saveOrder } from "./api";

import "./styles.css";
import Texts from "../common/texts";
import Footer from "../Footer";
import Header from "../Header";
import { setItem, getItem, clearStorage } from '../store';

const datesObj = require('./config/dates.json');
const timesObj = require('./config/times.json');

export default function Shop() {
  const [lang, setLang] = useState(getItem("shop-lang") || 'en');
  const [mode, setMode] = useState();
  const [message, setMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState();
  const { earliestSlotToday, slots } = timesObj;
  const [times, setTimes] = useState([...slots].map(t => ({ ...t, active: true })));

  const today = new Date();
  const pad = (num) => num < 10 ? '0' + num : '' + num;
  const todayStr = `${today.getFullYear()}-${pad(today.getMonth() + 1)}-${pad(today.getDate())}`

  const dates = [...datesObj];

  dates.forEach(d => {
    d.active = d.value >= todayStr;
  });

  const onlineOrdersActive = dates.filter(d => d.active).length > 0;

  let navigate = useNavigate();

  const nextPage = () => navigate(`/catalog`);

  const [address, setAddress] = useState({
    city: 'Berlin'
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    delivery_date: '',
    delivery_time: ''
  });

  const activateDeliveryTimes = (deliveryDate) => {
    const earliestDelivery = new Date();
    earliestDelivery.setHours(earliestDelivery.getHours() + 1);
    earliestDelivery.setMinutes(earliestDelivery.getMinutes() + 30);
    times.forEach(t => {
      const d = new Date(`${deliveryDate}T${t.value}`);
      t.active = !deliveryDate || (d.getTime() >= earliestDelivery.getTime() && !(deliveryDate === todayStr && t.value < earliestSlotToday));
    })

    setTimes(times);
  }

  const setAddressValue = (event) => {
    setErrorMsg();
    setAddress({ ...address, [event.target.name]: event.target.value });
  };

  const setFormAttr = (event) => {
    setErrorMsg();
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if (event.target.name === 'delivery_date') {
      activateDeliveryTimes(event.target.value);
    }
  };

  const submitForm = () => {
    if (!formData.name || !formData.phone || !(mode !== 'deliver' || (address.post_code && address.street))) {
      setErrorMsg('Please enter all details');
      return false;
    }

    setItem('customer_address', JSON.stringify(address));
    setItem('customer_name', formData.name);
    setItem('customer_phone', formData.phone);
    setItem('customer_email', formData.email);
    setItem('order_mode', mode);
    setItem('delivery_date', `${formData.delivery_date} ${formData.delivery_time}`);

    nextPage();
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    const qLang = query.get("lang");
    const lang = (qLang || getItem("shop-lang") || 'en');
    setLang(lang);
    if (qLang) {
      setItem("shop-lang", qLang);
    }

    setMode('pickup');
    activateDeliveryTimes((((dates || []).filter(d => d.active)[0]) || {}).value);

    if (query.get("success")) {
      setMessage(Texts({ identifier: 'success_msg', lang }));

      const basket = JSON.parse(getItem('basket') || '[]');
      if (basket.length) {
        saveOrder().then(() => {
          console.log('done');
          clearStorage();
        });
      }
    }

    if (query.get("canceled")) {
      setMessage(Texts({ identifier: 'cancelled_msg', lang }));
    }
  }, [times]);


  return (
    <>
      <Helmet>
        <title>{Texts({ identifier: "shopTitle", lang })}</title>
        <link rel="canonical" href="https://shop.theindique.com/" />
        <meta name="description" content={Texts({ identifier: 'shopMetaDescription', lang })} />
      </Helmet>
      <Header lang={lang} updateLang={(attr) => setLang(attr)} />

      <div className="shop container mt-5">
        {!!message &&
          <div className="infoMsg">{message}</div>
        }

        {!!errorMsg &&
          <div className="errorMsg">
            {errorMsg}
          </div>
        }

        {!onlineOrdersActive &&
          <div className="errorMsg">
            <Texts identifier="online_orders_inactive" lang={lang} />
          </div>
        }

        {!!onlineOrdersActive && (
          <>
            <div>
              <h3 className="title">1. <Texts identifier="q_mode_pickup_only" lang={lang} /></h3>
              {/* <h3 className="title">1. <Texts identifier="q_mode" lang={lang} /></h3>
        <button onClick={() => setMode('pickup')} className={mode === 'pickup' ? 'active' : 'inactive'}><Texts identifier="pickup_btn" lang={lang} /></button>
        <button onClick={() => setMode('deliver')} className={mode === 'deliver' ? 'active' : 'inactive'}><Texts identifier="delivery_btn" lang={lang} /></button> */}
            </div>

            <div>
              <h3 className="title">2. <Texts identifier="q_datetime" lang={lang} /></h3>
              <div>
                <select name="delivery_date" onChange={(event) => setFormAttr(event)}>
                  {dates.map((d, i) => d.active &&
                    <option key={'date_' + i} value={d.value}>{d.label}</option>
                  )}
                </select>
              </div>
              <div>
                <select name="delivery_time" onChange={(event) => setFormAttr(event)}>
                  {times.map((d, i) => d.active &&
                    <option key={'time_' + i} value={d.value}>{d.label}</option>
                  )}
                </select>
              </div>
            </div>

            {!!mode &&
              <div>
                <h3 className="title">3. <Texts identifier="q_details" lang={lang} /></h3>
                {mode === 'deliver' &&
                  <div>
                    <div className="formLabel"><Texts identifier="post_code" lang={lang} />:</div>
                    <div>
                      <select name="post_code" value={address.post_code} onChange={(event) => setAddressValue(event)}>
                        <option value=""></option>
                        <option value="10115">10115</option>
                        <option value="10117">10117</option>
                        <option value="10119">10119</option>
                        <option value="10178">10178</option>
                        <option value="10179">10179</option>
                        <option value="10405">10405</option>
                        <option value="10435">10435</option>
                        <option value="10437">10437</option>
                        <option value="10557">10557</option>
                        <option value="10777">10777</option>
                        <option value="10783">10783</option>
                        <option value="10785">10785</option>
                        <option value="10787">10787</option>
                        <option value="10789">10789</option>
                        <option value="10961">10961</option>
                        <option value="10963">10963</option>
                        <option value="10969">10969</option>
                        <option value="13355">13355</option>
                      </select>
                    </div>
                  </div>
                }
                <div>
                  <div className="formLabel"><Texts identifier="name" lang={lang} />:</div>
                  <div><input type="text" name="name" value={formData.name} onChange={(event) => setFormAttr(event)} /></div>
                </div>
                <div>
                  <div className="formLabel"><Texts identifier="email" lang={lang} />:</div>
                  <div><input type="email" name="email" value={formData.email} onChange={(event) => setFormAttr(event)} /></div>
                </div>
                <div>
                  <div className="formLabel"><Texts identifier="phone" lang={lang} />:</div>
                  <div><input type="text" name="phone" value={formData.phone} onChange={(event) => setFormAttr(event)} /></div>
                </div>
                {mode === 'deliver' &&
                  <div>
                    <div className="formLabel"><Texts identifier="address" lang={lang} />:</div>
                    <div>
                      <input type="text" name="street" value={address.street} onChange={(event) => setAddressValue(event)} />
                    </div>
                    <div>
                      <input type="text" name="address_notes" value={address.address_notes} onChange={(event) => setAddressValue(event)} />
                    </div>
                    <div className="half-columns">
                      <div><input type="text" disabled="disabled" name="post_code2" value={address.post_code} /></div>
                      <div><input type="text" disabled="disabled" name="city" value={address.city} /></div>
                    </div>
                  </div>
                }

                <div>
                  <div><button onClick={() => submitForm()}><Texts identifier="proceed_btn" lang={lang} /></button></div>
                </div>
              </div>
            }
          </>
        )}


      </div>

      <Footer lang={lang} />

    </>
  )
}