import React, { useEffect, useState } from "react";

import "./styles.css";
import promotionsData from './data.json';
import Header from "../Header";
import Footer from "../Footer";

export default function Promotion() {
    const now = new Date();
    const pad = (d) => d <10 ? `0${d}` : `${d}`;
    const today = `${now.getFullYear()}-${pad(now.getMonth()+1)}-${pad(now.getDate())}`;

    const [lang, setLang] = useState('en');
    const [currentDate, setCurrentDate] = useState(today);
    const [todaysData, setTodaysData] = useState({});
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang === 'de') {
        setLang('de');
    }


    const defaultData = promotionsData.find(d => !d.date);

    useEffect(() => {
        setTodaysData(promotionsData.find(d => d.date === currentDate) || defaultData);
    }, []);

    // const next = () => {
    //     const dt = new Date(currentDate);
    //     dt.setDate(dt.getDate() + 1);
    //     const str = `${dt.getFullYear()}-${pad(dt.getMonth()+1)}-${pad(dt.getDate())}`;
    //     setCurrentDate(str);
    //     setTodaysData(promotionsData.find(d => d.date === str) || defaultData);
    // }

  return (
    <div className="promotions">
        <div className="promotions-content">
            <Header updateLang={(attr) => setLang(attr) } />
            { !!todaysData && !!todaysData.title && 
            <div className="core-content">
                {/* <button onClick={() => next()}>Next</button> */}
                {/* <h1>{currentDate}</h1> */}
                <h1>{todaysData.title[lang]}</h1>
                <h3>{todaysData.timing[lang]}</h3>
                <p><i>{todaysData.description[lang]}</i></p>
                <h3>{todaysData.price}</h3>
                { !!todaysData.imgPath &&
                    <img className={`promo-img ${!!todaysData.noImgBackground ? 'no-bg' : ''}`} src={todaysData.imgPath} />
                }
            </div>
            }
            <Footer inheritBg={true} />
            <p className="attribution"><a href="https://www.freepik.com/free-photo/blurry-warm-christmas-bokeh-light-mobile-wallpaper_21631428.htm#query=blurred%20christmas%20lights&position=0&from_view=keyword">Image by rawpixel.com</a> on Freepik</p>
        </div>
        <div className="overlay" />
    </div>
  )
}