import { getItem } from "../store";
const textList = require('../common/texts.json');

export async function saveOrder() {
    const addr = JSON.parse(getItem('customer_address') || '{}');
    const basket = JSON.parse(getItem('basket') || '[]');
    const name = getItem('customer_name');
    const customer_email = getItem('customer_email');
    const totalAmount = getItem('totalAmount') || 0;
    const phone = getItem('customer_phone');
    const order_mode = getItem('order_mode')
    const deliveryDetails = `${addr.street || ''}, ${addr.address_notes || ''}, ${addr.post_code || ''} ${addr.city}`;
    const customer_address = `${name}, Tel: ${phone} ${(order_mode !== 'pickup') ? ', ' + deliveryDetails : ''}`;
    const order_details = basket.map(b => b.sys_name + (!!b.prefs ? ` (${b.prefs})` : ''));
    const now = new Date();
    const order_date = `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
    const delivery_date = getItem('delivery_date');
    const lang = getItem("shop-lang") || 'en';
    const order_mode_text = textList[`mode_${order_mode}`][lang];

    const payload = { customer_address, order_details, order_mode, order_date, delivery_date, basket, addr, lang, customer_email, totalAmount, order_mode_text };
    return await fetch(`/api/baskets`, {
      method: "POST",
      headers: { /*'Authorization': 'Bearer ' + localStorage.getItem("token"), */ "Content-Type": "application/json" },
      body: JSON.stringify(payload) 
    })
      .then((response) => {
        // If request is not successful, display error message
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
  
        return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
  }
