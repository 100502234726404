import React from "react";
import { setItem } from '../store';

import "./styles.css";

import ENIcon from "./images/en.webp";
import DEIcon from "./images/de.webp";
import Texts from "../common/texts";

export default function Header({ lang, updateLang }) {
  const setLang = (newLang) => {
    setItem('shop-lang', newLang);
    updateLang && updateLang(newLang);
  };

  return (
    <nav className="navbar navbar-expand-lg fixed-top main-nav navbar-dark header" id="header">
        <div className="container-fluid">
            <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse collapse justify-content-between" id="navbarCollapse">
                <a className="navbar-brand" href="/" title="The Indique">The Indique</a>
                <ul className="navbar-nav">
                    <li className="nav-item"><a className="nav-link" href="#about" title="About The Indique"><Texts root="header" lang={lang} identifier="about" /></a></li>
                    <li className="nav-item"><a className="nav-link" href="/shop" title="Online Order"><Texts root="header" lang={lang} identifier="order_online" /></a></li>
                    <li className="nav-item"><a className="nav-link" href="/reservations" title="Book a table"><Texts root="header" lang={lang} identifier="reserve_table" /></a></li>
                    <li className="nav-item"><a className="nav-link" href="/#menu" title="Menu"><Texts root="header" lang={lang} identifier="menu" /></a></li>
                    <li className="nav-item"><a className="nav-link" href="/#pictures" title="Pictures"><Texts root="header" lang={lang} identifier="pictures" /></a></li>
                    <li className="nav-item"><a className="nav-link" target="_blank" rel="noreferrer" href="https://blog.theindique.com" title="Blog"><Texts root="header" lang={lang} identifier="blog" /></a></li>
                    {lang === 'de' && <li className="nav-item flex" onClick={() => setLang('en')}><img src={DEIcon}/><span className="nav-link">&#8594;</span><img src={ENIcon} alt="Switch to English" /></li>}
                    {lang === 'en' && <li className="nav-item flex" onClick={() => setLang('de')} ><img src={ENIcon}/><span className="nav-link">&#8594;</span><img src={DEIcon} alt="Sprache auf Deutsch ändern" /></li>}
                </ul>
            </div>
        </div>
    </nav>
  )
}