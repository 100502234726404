export async function getRestaurantInfo() {
    return await fetch(`/api/reservations/slots`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        // If request is not successful, display error message
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
  
        return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
  }

export async function getReservation(accessCode) {
  return await fetch(`/api/reservations/bookings/${accessCode}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}
  
export async function cancelReservation(accessCode) {
  return await fetch(`/api/reservations/bookings/${accessCode}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

export async function createReservation(payload) {
    return await fetch(`/api/reservations/slots`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload)
    })
    .then((response) => {
    // If request is not successful, display error message
    if (!response.ok) {
        throw new Error("HTTP status " + response.status);
    }

    return response.json();
    })
    .catch((err) => {
    console.log(err);
    });
}

export async function updateReservation(access_code, payload) {
  return await fetch(`/api/reservations/bookings/${access_code}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
  })
  .then((response) => {
  // If request is not successful, display error message
  if (!response.ok) {
      throw new Error("HTTP status " + response.status);
  }

  return response.json();
  })
  .catch((err) => {
  console.log(err);
  });
}
