import React, { useEffect, useState } from "react";
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

import "./styles.css";
import ChatWindow from "./ChatWindow";

const socketUrl = process.env.REACT_APP_WS_URL;

export default function Chat({ lang }) {
    const [messageList, setMessageList] = useState([]);
    const [room, setRoom] = useState(uuidv4());
    const [socket, setSocket] = useState(io(socketUrl || '/', {
        autoConnect: false
    }));

    const buildMessage = (author, text) => ({
        author,
        type: 'text',
        data: { text }
    });

    const sendMessage = (text) => {
        if (text && !!text.trim()) {
            setMessageList([...messageList, buildMessage('them', text)]);
        }
    }

    useEffect(() => {
        setMessageList([]);
        socket.connect(true);
        socket.emit('join', { room, lang });
    }, [lang]);

    useEffect(() => {
        const onMessageReceived = (msg) => {
            sendMessage(msg);
        };

        socket.on('send-msg-response', onMessageReceived);

        return () => {
            socket.off('send-msg-response', onMessageReceived);
        };
    }, [messageList, socket]);

    const onMessageWasSent = (text) => {
        const message = buildMessage('me', text);
        setMessageList([...messageList, message]);
        socket.emit('new-msg', { msg: message.data.text, room, lang });
    }

    return (
        <div id="chatbox" className="chatbox">
            <ChatWindow lang={lang} messageList={messageList} messageCount={(messageList || []).length} onMessageSent={onMessageWasSent} />
        </div>
    );
}
