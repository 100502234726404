import React, { useState } from "react";

import "./styles.css";

import MenuTab from "./MenuTab";
import MenuContent from './data';

export default function Menu({ lang }) {
  const [ selectedTab, setSelectedTab ] = useState("tasting-menu");

  const TabNav = ({ lang, menuKey, selectedTab }) => {
    const name = MenuContent[menuKey].name[lang];
    return (
      <li className={`nav-item ${selectedTab === menuKey ? 'active' : ''}`} onClick={() => setSelectedTab(menuKey)}><a href={`#${menuKey}`} className="nav-link" id={`${menuKey}-tab"`} data-toggle="tab"
                  role="tab" aria-controls={menuKey} aria-selected="false" title={name}>{name}</a></li>
    )
  };

  const menuKeys = Object.keys(MenuContent);
  
  return (
    <section id="menu" className="menu">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 ">
            <ul className="nav justify-content-center menu-nav" role="tablist">
              {menuKeys.map((menuKey, index) => <TabNav lang={lang} menuKey={menuKey} selectedTab={selectedTab} key={`menu-tab-nav-${index}`} />)}
            </ul>
          </div>
        </div>
      </div>
      <div className="tab-content">
        <div className="row">
        {menuKeys.map((menuKey, index) => <MenuTab lang={lang} menuKey={menuKey} selectedTab={selectedTab} key={`menu-tab-content-${index}`} />)}
        </div>
      </div>
    </section>
  )
}