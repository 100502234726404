import IMG1 from './images/chicken-tikka.webp';
import IMG2 from './images/Paneer-Tikka.webp';
import IMG3 from './images/Paneer-Kathi-Roll.webp';
import IMG4 from './images/Bhel-Puri.webp';
import IMG5 from './images/Dal-Bukhara.webp';
import IMG6 from './images/Madhosh-Veggies.webp';
import IMG7 from './images/madhosh-chicken.webp';
import IMG8 from './images/chicken-manchurian.webp';
import IMG9 from './images/Veg-Manchurian.webp';
import IMG10 from './images/aloo-chaat.webp';
import IMG11 from './images/golguppe.webp';
import IMG12 from './images/rajkachori.webp';
import IMG13 from './images/crispy-corn.webp';
import IMG14 from './images/gobhi65.webp';
import IMG15 from './images/chicken65.webp';
import IMG16 from './images/hyderabadi-chicken-biryani.webp';
import IMG17 from './images/hyderabadi-veg-biryani.webp';
import IMG18 from './images/prawns-pepper-roast.webp';
import IMG19 from './images/indique-fish-roast.webp';
import IMG20 from './images/malabar-prawn-curry.webp';
import IMG21 from './images/prawn-thokku.webp';
import IMG22 from './images/fried-rice.webp';
import IMG23 from './images/veg-noodles.webp';
import IMG24 from './images/malabar-paratha.webp';
import IMG25 from './images/naan.webp';
import IMG26 from './images/rasagulla.webp';
import IMG27 from './images/gulab-jamun.webp';
import IMG28 from './images/kulfi.webp';
import IMG29 from './images/gin-jeera.webp';
import IMG30 from './images/dark-monsoon.webp';
import IMG31 from './images/Moscow-Mule.webp';
import IMG32 from './images/Blackberry-Mint-Smash.webp';
import IMG33 from './images/dirty-mango-lassi.webp';
import IMG34 from './images/soul-searcher.webp';
import IMG35 from './images/crazy-amrood.webp';
import IMG36 from './images/Tropical-Mango-Lassi.webp';
import IMG37 from './images/russian-connection.webp';
import IMG38 from './images/aperol-spritz.webp';
import IMG39 from './images/negroni.webp';

export default function MenuImg({ id }) {

const images = {
    "chicken-tikka": IMG1,
    "paneer-tikka": IMG2,
    "kathi-roll": IMG3,
    "bhel-puri": IMG4,
    "dal-bukhara": IMG5,
    "madhosh-veggies": IMG6,
    "madhosh-chicken": IMG7,
    "chicken-manchurian": IMG8,
    "veg-manchurian": IMG9,
    "delhi-aloo-chaat": IMG10,
    "golguppe": IMG11,
    "rajkachori": IMG12,
    "crispy-corn": IMG13,
    "gobhi-65": IMG14,
    "chicken-65": IMG15,
    "hyderabadi-chicken-biryani": IMG16,
    "hyderabadi-veg-biryani": IMG17,
    "prawns-pepper-roast": IMG18,
    "the-indique-fish-roast": IMG19,
    "malabar-prawn-curry": IMG20,
    "prawn-thokku": IMG21,
    "veg-fried-rice": IMG22,
    "hakka-noodles": IMG23,
    "malabar-paratha": IMG24,
    "naan": IMG25,
    "rasgulla": IMG26,
    "gulab-jamun": IMG27,
    "kulfi": IMG28,
    "gin-jeera": IMG29,
    "dark-monsoon": IMG30,
    "moscow-mule": IMG31,
    "blackberry-mint-smash": IMG32,
    "dirty-mango-lassi": IMG33,
    "soul-searcher": IMG34,
    "crazy-amrood": IMG35,
    "tropical-mango-lassi": IMG36,
    "russian-connection": IMG37,
    "aperol-spritz": IMG38,
    "negroni": IMG39
};

return images[id];
};