import React from "react";

import "./styles.css";
import Texts from "../../../common/texts";

export default function About({ lang }) {

  return (
    <section id="about">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 offset-lg-3">
                    <h1 className="title text-center"><Texts root="about" lang={lang} identifier="title" /></h1>
                    { Texts({ root: "about", lang, identifier: "introduction" }).map((text, index) => (
                        <p className="beige main-text" key={index}>{text}</p>
                    ))}
                </div>
            </div>

            <div className="col-lg-6 offset-lg-3 mission">
                <h2 className="title text-center"><Texts root="about" lang={lang} identifier="mission" /></h2>
                <p className=" main-text"><Texts root="about" lang={lang} identifier="mission_statement" /></p>
            </div>
        </div>
    </section>
  )
}