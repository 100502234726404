import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Texts from "../common/texts";
import { createReservation, getReservation, getRestaurantInfo, updateReservation } from "./api";
import "./styles.css";

export default function Reservations() {
    const browserLang = (navigator.language || navigator.userLanguage) || 'en-US';
    const [lang, setLang] = useState(browserLang.split('-')[0] || 'en');
    const [ reservation, setReservation ] = useState({});
    const [ availableTimesObj, setAvailableTimesObj ] = useState({});
    const [ errorMsg, setErrorMsg ] = useState("");
    const [ reserved, setReserved ] = useState(false);
    const [ title, setTitle ] = useState("");
    const [ descriptions, setDescriptions ] = useState("");
    const [ reservationTimes, setReservationTimes ] = useState([]);
    const { access_code } = useParams();

    const pad = (v) => v < 10 ? `0${v}` : `${v}`;

    const getReservationTimesForDate = (date, availableTimes) => {
        const d = resetDate(new Date(date));
        const today = resetDate(new Date());
        const now = new Date();
        const t = `${pad(now.getHours())}:${pad(now.getMinutes())}`;

        let reservationTimes = (d.getTime() < today.getTime()) ? [] : availableTimes[d.getDay()];
        if (d.getTime() === today.getTime()) {
            reservationTimes = reservationTimes.filter(r => r > t);
        }
        return reservationTimes;
    };

    const loadReservation = (availableTimes) => {
        getReservation(access_code).then(res => {
            if (!res || !res.length) {
                return;
            }
            const r = { ...res[0] };
            const t = new Date(r.reservation_time_start);
            setReservationTimes(getReservationTimesForDate(r.reservation_date, availableTimes));
            setReservation({ ...r, phone: r.contact_details, date: r.reservation_date, time: `${pad(t.getHours())}:${pad(t.getMinutes())}` });
        });
    };

    useEffect(() => {
        getRestaurantInfo().then(d => {
            if (!d) {
                return;
            }
            const { date, seats, availableTimes, title, descriptions } = d;
            setTitle(title);
            setDescriptions(descriptions[lang]);
            setAvailableTimesObj(availableTimes);
            setReservation({ date, seats });
            setReservationTimes(getReservationTimesForDate(date, availableTimes));

            if (access_code) {
                loadReservation(availableTimes);
            }
        })
    }, [lang, access_code]);

    const resetDate = (d) => {
        const midnight = new Date(d);
        midnight.setHours(0);
        midnight.setMinutes(0);
        midnight.setSeconds(0);
        midnight.setMilliseconds(0);
        return midnight;
    }

    const updateForm = (event) => {
        let res = { ...reservation };
        if (event.target.name === 'date') {
            setReservationTimes(getReservationTimesForDate(event.target.value, availableTimesObj));
            res['time'] = undefined;
        }
        setReservation({ ...res, [ event.target.name ]: event.target.value });
    };

    const formatDate = (d) => {
        const x = new Date(d);
        return `${x.getDate()}.${x.getMonth()+1}.${x.getFullYear()}`;
    }

    const save = () => {
        const payload = { ...reservation, lang };
        const fn = () => !!access_code ? updateReservation(access_code, payload) : createReservation(payload);
        fn().then((d) => {
            if (d.error) {
                return setErrorMsg(Texts({ identifier: 'msg_no_tables_available', lang }));
            }
            if (d.validationError) {
                return setErrorMsg(Texts({ identifier: 'msg_input_validation_error', lang }));
            }

            setErrorMsg(null);
            setReserved(true);
        })
    }

    const setTime = (t) => setReservation({ ...reservation, time: t });

  return (
    <>
    <Helmet>
          <title>{Texts({ identifier: "reservationsTitle", lang })}</title>
          <link rel="canonical" href="https://shop.theindique.com/reservations" />
          <meta name="description" content={Texts({ identifier: 'reservationsMetaDescription', lang })}/>
    </Helmet>
    <div className="reservations-root">

    <Container fluid={true} className="reservations">
        { !reserved &&
        <div className="clickable pull-right">
            { (lang === 'en') && 
                <span onClick={() => setLang('de')}>DE</span>
            }
            { (lang === 'de') && 
                <span onClick={() => setLang('en')}>EN</span>
            }
        </div>
        }
        
        <h1 className="center">{title}</h1>
        { !reserved &&
            <>
            <h2 className="center mb-5"><Texts identifier="heading_reserve_table" lang={lang} /></h2>
            <Row>
                <Col xs={12} lg={5} className="description">
                    {!!descriptions && descriptions.map((d, i) => (
                        <p key={`desc_${i}`}>{d}</p>
                    ))}
                </Col>
                <Col xs={12} lg={6}>
                    {!!errorMsg && (
                        <Row>
                            <Col xs={12} className="alert alert-danger"><i className="fas fa-times-circle"></i>&nbsp;{errorMsg}</Col>
                        </Row>
                    )}
                    <Form method="POST">
                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="date">
                                <Form.Label><i className="fas fa-calendar-alt" />&nbsp;<Texts identifier="reservation_date" lang={lang} /></Form.Label>
                                <Form.Control type="date" name="date" value={reservation.date || ''} onChange={(event) => updateForm(event)} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="time">
                                <Form.Label><i className="fas fa-clock" />&nbsp;<Texts identifier="reservation_time" lang={lang} /></Form.Label>
                                <ul className="time-slots">
                                    { !!reservationTimes && reservationTimes.map((r, i) => (
                                        <li onClick={() => setTime(r)} key={`res_${i}`} className={ reservation.time === r ? "active": ""}>{r}</li>
                                    ))}
                                </ul>
                                { !reservationTimes.length && (
                                    <p className="alert alert-warning"><i className="fas fa-exclamation-triangle"></i>&nbsp;<Texts identifier="msg_no_time_slots" lang={lang} /></p>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}> 
                            <Form.Group className="mb-3" controlId="seats">
                                <Form.Label><i className="fas fa-user-friends" />&nbsp;<Texts identifier="reservation_seats" lang={lang} /></Form.Label>
                                <Form.Control type="number" name="seats" value={reservation.seats || 0} placeholder={Texts({ identifier: "reservation_seats", lang })} onChange={(event) => updateForm(event)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}> 
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label><i className="fas fa-user" />&nbsp;<Texts identifier="name" lang={lang} /></Form.Label>
                                <Form.Control type="text" name="name" value={reservation.name || ''} placeholder={Texts({ identifier: "name", lang })} onChange={(event) => updateForm(event)} readOnly={ !!access_code } />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label><i className="fas fa-envelope" />&nbsp;<Texts identifier="email" lang={lang} /></Form.Label>
                                <Form.Control type="email" name="email" value={reservation.email || ''} placeholder={Texts({ identifier: "email", lang })} onChange={(event) => updateForm(event)}  readOnly={ !!access_code } />
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label><i className="fas fa-phone-alt" />&nbsp;<Texts identifier="phone" lang={lang} /></Form.Label>
                                <Form.Control type="text" name="phone" value={reservation.phone || ''} placeholder={Texts({ identifier: "phone", lang })} onChange={(event) => updateForm(event)}  readOnly={ !!access_code } />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}> 
                            <Form.Group className="mb-3" controlId="notes">
                                <Form.Label><i className="fas fa-sticky-note" />&nbsp;<Texts identifier="reservation_notes" lang={lang} /></Form.Label>
                                <Form.Control as="textarea" name="notes" value={reservation.notes || ''} placeholder={Texts({ identifier: "reservation_notes", lang })} onChange={(event) => updateForm(event)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                    <Col xs={12}> 
                        <Button onClick={() => save()}><Texts identifier="btn_reserve_table" lang={lang} /></Button>
                    </Col>
                    </Row>
                    </Form>
                </Col>
            </Row>
            </>
        }

        { !!reserved && 
        <>
            <h2 className="center mb-5"><Texts identifier="thank_you" lang={lang} /></h2>
            <Row>
                <Col xs={12} lg={5} className="description">
                <Texts identifier="reservation_confirmation" lang={lang} />
                </Col>
                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={12} lg={6}>
                            <strong><i className="fas fa-calendar-alt" />&nbsp;<Texts identifier="reservation_date" lang={lang} /></strong>
                        </Col>
                        <Col className="val" xs={12} lg={6}>
                            {formatDate(reservation.date)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <strong><i className="fas fa-clock" />&nbsp;<Texts identifier="reservation_time" lang={lang} /></strong>
                        </Col>
                        <Col className="val" xs={12} lg={6}>
                            {reservation.time}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <strong><i className="fas fa-user-friends" />&nbsp;<Texts identifier="reservation_seats" lang={lang} /></strong>
                        </Col>
                        <Col className="val" xs={6}>
                            {reservation.seats}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <strong><i className="fas fa-user" />&nbsp;<Texts identifier="name" lang={lang} /></strong>
                        </Col>
                        <Col className="val" xs={12} lg={6}>
                            {reservation.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <strong><i className="fas fa-envelope" />&nbsp;<Texts identifier="email" lang={lang} /></strong>
                        </Col>
                        <Col className="val" xs={12} lg={6}>
                            {reservation.email}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <strong><i className="fas fa-phone-alt" />&nbsp;<Texts identifier="phone" lang={lang} /></strong>
                        </Col>
                        <Col className="val" xs={12} lg={6}>
                            {reservation.phone}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <strong><i className="fas fa-sticky-note" />&nbsp;<Texts identifier="reservation_notes" lang={lang} /></strong>
                        </Col>
                        <Col className="val" xs={12} lg={6}>
                            {reservation.notes}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
        }

    </Container>
    </div>
    </>
  )
}