import React from "react";
import "./styles.css";

import WoltIcon from "./wolt.webp";
import Texts from "../common/texts";

export default function Footer({ lang, inheritBg = false }) {
    return (
        <footer className={`ftco-footer ftco-bg-dark ftco-section ${!!inheritBg ? 'inheritBg' : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 offset-lg-1 col-10 offset-1">
                        <div className="ftco-footer-widget">
                            <h2 className="ftco-heading-2">The Indique</h2>
                            <p><i className="fas fa-map-marker-alt"></i>&nbsp;Ziegelstrasse 29, 10117 Berlin</p>
                            <p><i className="fas fa-phone-alt"></i>&nbsp;030 28622928</p>
                            <p><Texts lang={lang} root="footer" identifier="delivery" />: <a href="https://wolt.com/en/deu/berlin/restaurant/the-indique" target="_blank" rel="noreferrer" title="Wolt"><img src={WoltIcon} className="foodpanda-icon" alt="Wolt" /></a></p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="ftco-footer-widget">
                            <h2 className="ftco-heading-2"><i className="far fa-calendar-alt"></i>&nbsp;<Texts lang={lang} root="footer" identifier="hoursTitle" /></h2>
                            <ul className="list-unstyled open-hours">
                                <li className="flex">
                                    <span><Texts lang={lang} root="footer" identifier="monday" /></span>
                                    <span><Texts lang={lang} root="footer" identifier="closed" /></span>
                                </li>
                                <li className="flex">
                                    <span><Texts lang={lang} root="footer" identifier="tuesday" /></span>
                                    <span><Texts lang={lang} root="footer" identifier="closed" /></span>
                                </li>
                                <li className="flex">
                                    <span><Texts lang={lang} root="footer" identifier="wednesday" /></span>
                                    <span>12:00-15:00 &</span>
                                </li>
                                <li className="flex">
                                    <span></span>
                                    <span>17:00-23:00</span>
                                </li>
                                <li className="flex">
                                    <span><Texts lang={lang} root="footer" identifier="thursday" /></span>
                                    <span>12:00-15:00 &</span>
                                </li>
                                <li className="flex">
                                    <span></span>
                                    <span>17:00-23:00</span>
                                </li>
                                <li className="flex">
                                    <span><Texts lang={lang} root="footer" identifier="friday" /></span>
                                    <span>12:00-15:00 &</span>
                                </li>
                                <li className="flex">
                                    <span></span>
                                    <span>17:00-23:00</span>
                                </li>
                                <li className="flex">
                                    <span><Texts lang={lang} root="footer" identifier="saturday" /></span>
                                    <span>12:00-15:00 &</span>
                                </li>
                                <li className="flex">
                                    <span></span>
                                    <span>17:00-23:00</span>
                                </li>
                                <li className="flex">
                                    <span><Texts lang={lang} root="footer" identifier="sunday" /></span>
                                    <span>12:00-15:00 &</span>
                                </li>
                                <li className="flex">
                                    <span></span>
                                    <span>17:00-23:00</span>
                                </li>
                            </ul>
                            {/* <p><i>* Open as a cafe from 15:00 - 17:00 on weekdays. Drinks only.</i></p> */}
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="ftco-footer-widget">
                            <h2 className="ftco-heading-2">Social Media</h2>
                            <ul className="list-unstyled social-media">
                                <li>
                                    <a href="https://www.facebook.com/theindique/" target="_blank" rel="noreferrer" title="Facebook">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/the_indique" target="_blank" rel="noreferrer" title="Twitter">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/theindique/" target="_blank" rel="noreferrer" title="Instagram">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.pinterest.de/072em4gu3112ax5mbxcfi7qedg4ybs/" target="_blank" rel="noreferrer" title="Pinterest">
                                        <i className="fab fa-pinterest"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="spacer"></div>
                        <p>
                            &copy; 2023 All rights reserved
                        </p>
                    </div>
                </div>
            </div>
        </footer>

    )
}