import React from "react";

import "./styles.css";
import Logo from "../../images/logo-small.webp";
import Texts from "../../../common/texts";

export default function Membership({ lang }) {

  return (
    <section name="membership" className="membership" id="membership">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <div className=" text-center">
                        <img src={Logo} className="small-logo" alt="The Indique Logo" />
                    </div>
                    <h2 className="title text-center"><Texts lang={lang} root="membership" identifier="title" /></h2>
                    <p><Texts lang={lang} root="membership" identifier="intro" /></p>
                    <ul>
                        {Texts({lang, root: "membership", identifier: "benefits"}).map((benefit, index) => (
                            <li key={index}>{benefit}</li>
                        ))}
                    </ul>
                    <p><Texts lang={lang} root="membership" identifier="contact" /></p>
                </div>
            </div>
        </div>
    </section>
  )
}