import TastingMenu from './tasting-menu.json';
import PrixFixe from './prix-fixe-menu.json';
import Dinner from './dinner-menu.json';
import LunchMenu from './lunch-menu.json';
import Desserts from './desserts.json';
import Drinks from './drinks.json';

const Menu = {
    "tasting-menu": TastingMenu,
    "prix-fixe": PrixFixe,
    "dinner-menu": Dinner,
    "lunch-menu": LunchMenu,
    "desserts": Desserts,
    "drinks": Drinks
};

export default Menu;