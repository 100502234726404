import React from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import Texts from "../../../common/texts";

export default function WebMetaData({ lang }) {

  const structuredJSONObj = {
    "@context": "http://schema.org", 
    "@type": "Restaurant", 
    "url" : "http://www.theindique.com", 
    "name": "The Indique", 
    "image" : "http://www.theindique.com/images/theindique.webp", 
    "telephone": "+49 (0) 30 28622928", 
    "description": "The Indique is a restaurant and cocktail bar in Berlin that serves modern Indian cuisine with specialities in kathi rolls, chaat and spiced grills", 
    "address": {
        "@type": "PostalAddress", 
        "streetAddress": "Ziegelstr 29", 
        "addressLocality": "Berlin", 
        "addressRegion": "DE", 
        "postalCode": "10117"
      }, 
    "servesCuisine": ["Modern Indian cuisine", "Moderne indische Küche"]
  };
  const structuredJSON = JSON.stringify(structuredJSONObj);

  return (
    <HelmetProvider>

    <Helmet>
      <title>{Texts({ root: 'metadata', identifier: "title", lang })}</title>
      <link rel="canonical" href={Texts({ root: 'metadata', identifier: 'url', lang })} />
      <meta name="description" content={Texts({ root: 'metadata', identifier: 'description', lang })} />

      <meta property="og:url"                content={Texts({ root: 'metadata', identifier: 'url', lang })} />
      <meta property="og:type"               content="website" />
      <meta property="og:title"              content={Texts({ root: 'metadata', identifier: 'twitterTitle', lang })} />
      <meta property="og:description"        content={Texts({ root: 'metadata', identifier: 'twitterDescription', lang })} />
      <meta property="og:image"              content="https://www.theindique.com/images/theindique.webp" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@the_indique" />
      <meta name="twitter:creator" content="@the_indique" />
      <meta name="twitter:title" content={Texts({ root: 'metadata', identifier: 'twitterTitle', lang })} />
      <meta name="twitter:description" content={Texts({ root: 'metadata', identifier: 'twitterDescription', lang })} />
      <meta name="twitter:image" content="https://www.theindique.com/images/theindique.webp" />

      <script className='structured-data-list' type="application/ld+json">{structuredJSON}</script>
    </Helmet>
    </HelmetProvider>
  )
}
