import React from "react";

import "./styles.css";
import Texts from "../../../common/texts";

export default function ReserveNow({ lang }) {

    return (
        <section id="reservations" className="reservations">
            <div className="overlay">
                <div className="container banner-text">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="large"><Texts lang={lang} root="reserve-table" identifier="title" /></h2>
                            <p><Texts lang={lang} root="reserve-table" identifier="timings" /></p>
                            <p><Texts lang={lang} root="reserve-table" identifier="phone" /></p>
                            <p>Email: reservations@theindique.com</p>
                            <a href="/reservations" className="reserve-now" target="_blank" title="Reserve Now"><Texts lang={lang} root="reserve-table" identifier="btn" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}