import React from "react";

import "./styles.css";

import { ReactComponent as RG1 } from "./images/rg1.svg";
import { ReactComponent as RG2 } from "./images/rg2.svg";

import TA from "./images/tripadvisor.webp";

export default function Awards({ lang }) {

  return (
  <section name="awards" id="awards">
    <div className="container">
        <div className="row">
            <div className="offset-2 col-9 offset-lg-3 col-lg-3 mt-3">
              <a href="https://restaurantguru.com/The-Indique-Berlin" target="_blank" rel="noreferrer" title="The Indique on Restaurant Guru">
              <link href="https://awards.infcdn.net/transparent_v3.css" rel="stylesheet"/><div id="aw_transp" className="aw_transp_black"> <RG1 /> <div className="aw-arc "> <RG2 /> </div> <div className="aw_year">2022</div> <span>Restaurant Guru</span></div>
              </a>
                
            </div>

            <div className="offset-2 col-9 offset-lg-1 col-lg-4 mt-3">
                <a href="https://www.tripadvisor.de/Restaurant_Review-g187323-d23558249-Reviews-The_Indique-Berlin.html" title="The Indique on Tripadvisor"><img src={TA} alt="The Indique on Tripadvisor" /></a>
            </div>
        </div>
    </div>
    </section>
  )
};