import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Texts from "../common/texts";
import { cancelReservation, getRestaurantInfo } from "./api";

export default function ReservationCancellation() {
    const browserLang = (navigator.language || navigator.userLanguage) || 'en-US';
    const lang = browserLang.split('-')[0] || 'en';
    const [ title, setTitle ] = useState("");
    const [cancelled, setCancelled ] = useState(false);
    const [error, setError ] = useState(false);
    const { access_code } = useParams();

    useEffect(() => {
        getRestaurantInfo().then(d => {
            if (!d) {
                return;
            }
            const { title } = d;
            setTitle(title);
            cancelReservation(access_code).then(() => {
                setCancelled(true);
            }).catch(() => setError(true));
        });
    }, [access_code]);

    return (
        <Container fluid={true} className="reservations">
                <>
                <h1 className="center">{title}</h1>
                { !!cancelled && (
                    <Texts identifier="msg_cancelled" lang={lang} />
                )}

                { !!error && (
                    <Texts identifier="msg_error" lang={lang} />
                )}
                </>
        </Container>
    )
};
