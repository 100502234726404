const textList = require('./texts.json');
const browserLang = (navigator.language || navigator.userLanguage) || 'en-US';

const handleMissingKey = (root, identifier, lang) => {
    return (root && root.trim() && (!textList[root] || (!textList[root][identifier]) || !textList[root][identifier][lang])) || (!root && ((!textList[identifier]) || !textList[identifier][lang]));
}

export default function Texts({ root, identifier, lang }) {
    const derivedLang = lang || browserLang;
    const language = (derivedLang === 'en' || derivedLang === 'de') ? derivedLang : 'en';
    if (handleMissingKey(root, identifier, language)) {
        return '';
    }

    if (root && root.trim()) {
        return textList[root][identifier][language];
    }
    return textList[identifier][language];
};
