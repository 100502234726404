import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "./styles.css";
import Header from "../Header";
import Footer from "../Footer";
import Banner from "./components/Banner";
import About from "./components/About";
import ReserveNow from "./components/ReserveNow";
import Menu from "./components/Menu";
import Membership from "./components/Membership";
import Pictures from "./components/Pictures";
import Stories from "./components/Stories";
import WebMetaData from "./components/WebMetaData";
import Awards from "./components/Awards";
import { getItem } from "../store";
import Chat from "../Chat";

export default function Home() {
  const [lang, setLang] = useState(getItem("shop-lang") || 'en');
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/de") {
      setLang("de");
    }
  }, [location]);

  return (
    <>
      <WebMetaData lang={lang} />
      <Header lang={lang} updateLang={(attr) => setLang(attr)} />
      <Chat lang={lang} />
      <div className="home">
        <Banner lang={lang} />
        <About lang={lang} />
        <Awards lang={lang} />
        <ReserveNow lang={lang} />
        <Menu lang={lang} />
        <Membership lang={lang} />
        <Stories lang={lang} />
        <Pictures lang={lang} />
      </div>
      <Footer lang={lang} />
    </>
  )
}