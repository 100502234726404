import React, { useEffect, useRef, useState } from "react";

import './styles.css';

export default function ChatWindow({ lang, messageList, onMessageSent, messageCount }) {
    const inputReference = useRef(null);
    const messagesEndRef = useRef(null)

    const [text, setText] = useState('');
    const [showChat, setShowChat] = useState(false);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        if (inputReference && inputReference.current) {
            inputReference.current.focus();
        }
        scrollToBottom();
    }, [showChat, lang, messageCount]);

    const onChange = (e) => {
        setText(e.target.value);
    };

    const handleSubmit = (event) => {
        onMessageSent(text);
        setText('');
        event.preventDefault();
    };

    return (
        <div id="chat-container">
            <div className={`chat-launcher ${!!showChat ? 'open' : ''}`} onClick={() => setShowChat(!showChat)}>
                {!!showChat && <i className="fas fa-times"></i>}
                {!showChat && <i className="fas fa-comment"></i>}
            </div>

            {!!showChat &&
                <div className="chat-window">
                    <div className="chat-header">
                        <img className="chat-header-img" src="https://www.theindique.com/logo.png" alt="" />
                        <div className="chat-header-team-name"> Virtual Assistant </div>
                        <div className="chat-header-close-button" onClick={() => setShowChat(false)}>
                            <i className="fas fa-times"></i>
                        </div>
                    </div>
                    <div className="chat-message-list">
                        {messageList.map((message, index) => (
                            <div className="chat-message" key={index}>
                                <div className={message.author === 'them' ? "chat-message-content received" : "chat-message-content sent"}>
                                    <div className="chat-message-avatar"><i className="fas fa-user"></i></div>
                                    <div className="chat-message-text">
                                        <span>{message.data.text}</span>
                                    </div>
                                </div>
                            </div>
                        )
                        )}
                        <div ref={messagesEndRef} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="chat-user-input">
                            <input type="text" className="chat-user-input-text" placeholder="Type a message..." ref={inputReference} value={text} onChange={e => onChange(e)} />
                            <button type="submit" className="chat-user-input-send"><i className="fas fa-paper-plane"></i></button>
                        </div>
                    </form>
                </div>
            }
        </div>
    );
}