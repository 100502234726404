import React from "react";

import "./styles.css";
import MenuContent from './data';

export default function MenuTab({ lang, menuKey, selectedTab }) {

  return (
    <div className={`tab-pane row row-cols-lg-2 row-cols-lg-auto ${selectedTab === menuKey ? 'active' : ''}`} id={menuKey} role="tabpanel" aria-labelledby={`nav-${menuKey}-tab`}>
        {MenuContent[menuKey].content.map((cat, index) => (
        <div className={MenuContent[menuKey].columns === 1 ? "col-lg-6 offset-lg-3": "col-lg-4 offset-lg-1 col-10 offset-1"} key={`${menuKey}-${index}`}>
        <div className="block-cat text-center">
            <h2>{cat.title[lang]}</h2>
        </div>
        {cat.description && cat.description[lang] && 
        <div className="block-cat text-center">
            <p><i>{cat.description[lang]}</i></p>
        </div>
        }
        {cat.items.map((item, itemIndex) => (
        <div className="block-content" key={`${menuKey}-${index}-${itemIndex}`}>
            <div className={item.subheading ? "subheading mt-5" :  "menu-item-title"}>
            <div>{item.name[lang]}</div>
            {item.price && <div className={`block-price ${item.subheading ? 'small' : '' }`}>{item.price}</div>}
            {item.prices && <div className={`flex block-price ${item.subheading ? 'small' : '' }`}>
                {item.prices.map((price, priceIndex) => (
                <div key={`${menuKey}-${index}-${itemIndex}-${priceIndex}`}>{price}</div>
                ))}
            </div>}
            </div>
            {item.descriptions && item.descriptions[lang] && item.descriptions[lang].map((desc, descIndex) => (
            <p key={`${menuKey}-${index}-${itemIndex}-${descIndex}`}>{desc}</p>
            ))}
            <span className="dots"></span>
        </div>
        ))}
        </div>
        ))}
    </div>
  )
}