export async function getProducts() {
    return await fetch(`/api/products`, {
      method: "GET",
      headers: { /*'Authorization': 'Bearer ' + localStorage.getItem("token"), */ "Content-Type": "application/json" },
    })
      .then((response) => {
        // If request is not successful, display error message
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
  
        return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
  }
