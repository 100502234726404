import React from "react";

import "./styles.css";

export default function Banner({ lang }) {

  return (
    <div className="jumbotron hero-banner">
        <div className="overlay">
            <div className="banner-logo">
            </div>
        </div>
    </div>
  )
}