import React, { useState } from "react";

import "./styles.css";

import Pic1 from "./images/gallery/p1.webp";
import Pic2 from "./images/gallery/p2.webp";
import Pic3 from "./images/gallery/p3.webp";
import Pic4 from "./images/gallery/p4.webp";
import Pic5 from "./images/gallery/p5.webp";
import Pic6 from "./images/gallery/p6.webp";
import Pic7 from "./images/gallery/p7.webp";
import Pic8 from "./images/gallery/p8.webp";
import Pic9 from "./images/gallery/p9.webp";
import Pic10 from "./images/gallery/p10.webp";
import Pic11 from "./images/gallery/p11.webp";
import Pic12 from "./images/gallery/p12.webp";
import Pic13 from "./images/gallery/p13.webp";
import Pic14 from "./images/gallery/p14.webp";
import Pic15 from "./images/gallery/p15.webp";
import Texts from "../../../common/texts";

export default function Pictures({ lang }) {

    const [ currentPic, setCurrentPic ] = useState(1);

    const pics = [
        { id: 1, src: Pic1, alt: "The Indique Entrance" },
        { id: 2, src: Pic2, alt: "View from the door" },
        { id: 3, src: Pic3, alt: "main dining room" },
        { id: 4, src: Pic4, alt: "The Indique Bar" },
        { id: 5, src: Pic5, alt: "Second dining room" },
        { id: 6, src: Pic6, alt: "Close up of our dining space" },
        { id: 7, src: Pic7, alt: "Kathi rolls" },
        { id: 8, src: Pic8, alt: "Indian Street food" },
        { id: 9, src: Pic9, alt: "Indian style Fried chicken" },
        { id: 10, src: Pic10, alt: "Dum Biryani" },
        { id: 11, src: Pic11, alt: "Chicken 65" },
        { id: 12, src: Pic12, alt: "Prawn Pepper Roast" },
        { id: 13, src: Pic13, alt: "Spicy but amazing prawns" },
        { id: 14, src: Pic14, alt: "Paneer Tikka" },
        { id: 15, src: Pic15, alt: "Vegetarian Manchurian" }
    ]

    const plusSlides = (n) => {
        if ((currentPic + n) < 1) {
            return;
        }
        if ((currentPic + n) > pics.length) {
            return;
        }
        setCurrentPic(currentPic + n);
    };

  return (
    <section name="pictures" className="pictures" id="pictures">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h2 className="title"><Texts lang={lang} root="pictures" identifier="title" /></h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 text-center">
                    <a href="/menu-pics" title="Pictures from our menu"><Texts lang={lang} root="pictures" identifier="menu" /></a>
                </div>
            </div>
        </div> 

        <div className="row">
          <div id="galleryModal" className="pic-modal">
            <div className="modal-content">

                {pics.map((pic, index) => (
                    <div className={`gallery-images ${currentPic === (index + 1) ? 'active' : ''}`} key={pic.id}>
                        <div className="numbertext">{index + 1} / {pics.length}</div>
                        <img src={pic.src} alt={pic.alt} />
                    </div>
                ))}

              <span className="prev" onClick={() => plusSlides(-1)} title="previous image">&#10094;</span>
              <span className="next" onClick={() => plusSlides(1)} title="next image">&#10095;</span>

            </div>
          </div>
          </div>
          
    </section>
  )
}