export function setItem(name, value) {
    sessionStorage.setItem(name, value);
}

export function getItem(name) {
    return sessionStorage.getItem(name);
}

export function isSessionStorageSupported() {
    var storage = window.sessionStorage;
    try {
      storage.setItem('test', 'test');
      storage.removeItem('test');    
      return true;
    } catch (e) {
      return false;
    }
}

export function clearStorage() {
    sessionStorage.removeItem('basket');
    sessionStorage.removeItem('order_mode');
    sessionStorage.removeItem('customer_address');
    sessionStorage.removeItem('customer_name');
    sessionStorage.removeItem('customer_email');
    sessionStorage.removeItem('totalAmount');
    sessionStorage.removeItem('customer_phone');
}