import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import "./styles.css";

import MenuItems from "./data.json";
import MenuImg from "./menu";
import Header from "../Header";
import Footer from "../Footer";
import { getItem } from "../store";
import Texts from "../common/texts";

const allItems = MenuItems.reduce((acc, section) => {
    return acc.concat(section.items);
}, []);

export default function Recipe() {
    let { id: idParam } = useParams();
    const [id, setId] = useState('');
    const [recipe, setRecipe] = useState({});
    const [lang, setLang] = useState(getItem("shop-lang") || 'en');
    const [structuredJSONObj, setStructuredJSONObj] = useState({});

    useEffect(() => {
        let id = idParam;
        if (idParam && idParam.indexOf('.html') !== -1) {
            id = idParam.replace('.html', '');
        }

        setId(id);
        const r = allItems.find((item) => item.id === id);
        setRecipe(r);
        setStructuredJSONObj({
            "@context": "http://schema.org", "@type": "Recipe", "url"
                : `https://www.theindique.com/recipes/${id}`, "name": r.name, "description"
                : r.desc, "author": {
                    "@type": "Person",
                    "name": "The Indique"
                },
                "image": `https://www.theindique.com${MenuImg({ id })}`,
            "datePublished": "2023-01-20",
            "keywords": "Indian Cuisine, Spicy Food, Cocktails",
            "recipeCuisine": "Indian"
        })
    }, [id]);

    const structuredJSON = JSON.stringify(structuredJSONObj);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{`The Indique:  ${recipe.name}`}</title>
                    <link rel="canonical" href={`${Texts({ root: 'metadata', identifier: 'url', lang })}/recipes/${id}`} />
                    <script type="application/ld+json">{structuredJSON}</script>
                </Helmet>

                <Header lang={lang} updateLang={(attr) => setLang(attr)} />
                <div className="mt-5 menu-pics-body">
                    <section className="menu-pics-section">
                        <h1 className="title text-center">The Indique: {recipe.name}</h1>

                        <div className="menu-pics">
                            <div className="menu-item">
                                <div className="menu-item-picture"><img src={MenuImg({ id })} /></div>
                                <div className="menu-item-name" data-bs-toggle="modal" data-bs-target="#ctModal">{recipe.name}</div>
                                <div className="mission"><p>{recipe.desc}</p></div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer lang={lang} />

            </HelmetProvider>
        </>
    )
}