import React from "react";

import "./styles.css";
import Texts from "../../../common/texts";

export default function Stories({ lang }) {

  return (
    <section name="food-stories" className="food-stories" id="food-stories">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 offset-lg-3">
                    <h2 className="title text-center"><Texts lang={lang} root="stories" identifier="title" /></h2>
                    <p className="beige main-text"><Texts lang={lang} root="stories" identifier="intro" /></p>
                    <div>
                        <div className="block-cat text-center">
                            <h3>Chaat</h3>
                        </div>
                        { Texts({lang, root: "stories", identifier: "chaat"}).map((text, index) => (
                            <p className="beige main-text" key={index}>{text}</p>
                        )) }
                    </div>

                    <div>
                        <div className="block-cat text-center">
                            <h3>Hyderabadi Biryani</h3>
                        </div>
                        { Texts({lang, root: "stories", identifier: "biryani"}).map((text, index) => (
                            <p className="beige main-text" key={index}>{text}</p>
                        )) }
                    </div>

                    <div>
                        <div className="block-cat text-center">
                            <h3>Prawns Pepper Roast</h3>
                        </div>

                        { Texts({lang, root: "stories", identifier: "ppr"}).map((text, index) => (
                            <p className="beige main-text" key={index}>{text}</p>
                        )) }
                    </div>

                    <div>
                        <div className="block-cat text-center">
                            <h3>Indian Chinese cuisine</h3>
                        </div>

                        { Texts({lang, root: "stories", identifier: "indoChinese"}).map((text, index) => (
                            <p className="beige main-text" key={index}>{text}</p>
                        )) }
                    </div>

                    <div>
                        <div className="block-cat text-center">
                            <h3>Raj Kachori</h3>
                        </div>

                        { Texts({lang, root: "stories", identifier: "rajKachori"}).map((text, index) => (
                            <p className="beige main-text" key={index}>{text}</p>
                        )) }
                    </div>

                </div>
            </div>
        </div>
    </section>
  )
}