import React, { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import "./styles.css";
import { getItem } from "../store";
import Header from "../Header";
import Footer from "../Footer";

import MenuItems from "./data.json";
import MenuImg from "./menu";
import Texts from "../common/texts";

export default function MenuPics() {
    const [lang, setLang] = useState(getItem("shop-lang") || 'en');

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{`The Indique: Menu Pictures`}</title>
                    <link rel="canonical" href={`${Texts({ root: 'metadata', identifier: 'url', lang })}/menu-pics`} />
                </Helmet>
                <Header lang={lang} updateLang={(attr) => setLang(attr)} />
                <div className="mt-5 menu-pics-body">
                    {MenuItems.map((section, sIndex) => (
                        <section className="menu-pics-section" key={`section-${sIndex}`}>
                            <h1 className="title text-center">The Indique: Pictures from our menu</h1>
                            <h2 className="title text-center">{section.name}</h2>
                            <div className="menu-pics">
                                {section.items.map((item, iIndex) => (
                                    <div className="menu-item" key={`section-${sIndex}-${iIndex}`}>
                                        <div className="menu-item-picture"><img src={MenuImg({ id: item.id })} /></div>
                                        <div className="menu-item-name" data-bs-toggle="modal" data-bs-target={`#ctModal-${sIndex}-${iIndex}`}>{item.name}</div>

                                        <div className="modal fade" id={`ctModal-${sIndex}-${iIndex}`} tabindex="-1" aria-labelledby="ctModalLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-body">
                                                        {item.desc}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-center small"><a href={`/recipes/${item.id}`}>Read more</a></p>
                                    </div>
                                ))}
                            </div>
                        </section>
                    ))}
                </div>
                <Footer lang={lang} />
            </HelmetProvider>
        </>
    )
};